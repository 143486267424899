// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const savedAuth = sessionStorage.getItem('auth');
    return savedAuth ? JSON.parse(savedAuth) : null;
  });

  useEffect(() => {
    if (auth) {
      sessionStorage.setItem('auth', JSON.stringify(auth));
    } else {
      sessionStorage.removeItem('auth');
    }
  }, [auth]);

  const login = async (username, password) => {
    try {
      const response = await axios.post('https://qrscan.best.com.bo/login.php', {
        username,
        password,
      });
      if (response.data.success) {
        setAuth({ username });
        return { success: true, message: response.data.message };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      return { success: false, message: 'Error al conectar con el servidor' };
    }
  };

  const logout = () => {
    setAuth(null);
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
// Login.js
import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { AuthContext } from '../contexts/AuthContext';
import NavBar from '../navbar/NavBar';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login } = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await login(username, password);
    setMessage(result.message);
    if (result.success) {
      // Redirigir a la ruta raíz
      window.location.href = '/';
    }
  };

  return (
    <div className='login-page'>
    <NavBar />
    <Container className="login-container mt-5">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h1 className="text-center">Inicio de Sesión</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername">
              <Form.Label>Nombre de Usuario</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={username}
                onChange={handleInputChange}
                placeholder="Ingresa tu nombre de usuario"
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                placeholder="Ingresa tu contraseña"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Iniciar Sesión
            </Button>
          </Form>
          {message && <Alert variant={message.includes('exitoso') ? 'success' : 'danger'} className="mt-3">{message}</Alert>}
        </Col>
      </Row>
    </Container>

    </div>
  );
};

export default Login;
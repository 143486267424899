import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import NavBar from '../navbar/NavBar';
import axios from 'axios';
import { useSpring, useTransition, animated } from '@react-spring/web';
import './WatchPoints.css';

const WatchPoints = () => {
  const [carnet, setCarnet] = useState('');
  const [points, setPoints] = useState(null);
  const [error, setError] = useState(null);
  const [prevPoints, setPrevPoints] = useState(0);

  const handleInputChange = (e) => {
    setCarnet(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`https://qrscan.best.com.bo/getPoints.php?carnet=${carnet}`);
      if (response.data.error) {
        setError(response.data.error);
        setPoints(null);
      } else {
        setPrevPoints(points || 0);
        setPoints(response.data.points);
        setError(null);
      }
    } catch (err) {
      setError('Error al obtener los puntos. Por favor, verifica tu carnet.');
      setPoints(null);
    }
  };

  const containerSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 500 }
  });

  const props = useSpring({
    from: { number: prevPoints },
    number: points || 0,
    delay: 200,
    config: { duration: 1000 }
  });

  const transitions = useTransition(points !== null, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
    config: { duration: 500 }
  });

  return (
    <div className="WatchPoints">
      <NavBar />
      <animated.div style={containerSpring}>
        <Container className="watch-points-container mt-5">
          <Row className="justify-content-md-center">
            <Col md="6">
              <h1 className="text-center">Consulta de Puntos</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formCarnet">
                  <Form.Label>Ingresa tu carnet</Form.Label>
                  <Form.Control
                    type="number"
                    value={carnet}
                    onChange={handleInputChange}
                    placeholder="Ingresa tu carnet"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Consultar
                </Button>
              </Form>
              {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
              {transitions((style, item) =>
                item ? (
                  <animated.div style={style}>
                    <Alert variant="success" className="mt-3 points-display">
                      <h2>Tus Puntos: <animated.span>{props.number.to(n => n.toFixed(0))}</animated.span></h2>
                    </Alert>
                  </animated.div>
                ) : ''
              )}
            </Col>
          </Row>
        </Container>
      </animated.div>
    </div>
  );
};

export default WatchPoints;
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import NavBar from '../navbar/NavBar';
import './CreateMember.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';

const CreateMember = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate('/'); // Redirige a la página de inicio de sesión si no está autenticado
    }
  }, [auth, navigate]);

  const [formData, setFormData] = useState({
    nombre: '',
    carnet: '',
    email: '',
    celular: '',
    fecha_nacimiento: '',
    ciudad: '',
    direccion: '',
    codigo: '', // Añadir campo para el código del cliente
    fechaInicial: '', // Añadir campo para la fecha inicial
    fechaFinal: '' // Añadir campo para la fecha final
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'text' ? value.toUpperCase() : value // Convertir a mayúsculas solo si es texto
    });
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Formatear las fechas antes de enviarlas al backend
      const formattedFormData = {
        ...formData,
        fechaInicial: formatDate(formData.fechaInicial),
        fechaFinal: formatDate(formData.fechaFinal)
      };

      // Enviar los datos del formulario al backend
      const registerResponse = await axios.post('https://qrscan.best.com.bo/register.php', formattedFormData);
      setResponseMessage(registerResponse.data.message);
      setShowAlert(true);
    } catch (error) {
      setResponseMessage('Error al registrar el miembro: ' + error.message);
      setShowAlert(true);
    }
  };

  return (
    <div className="CreateMember">
      <NavBar />
      <Container className="create-member-container">
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h1 className="text-center">Registro de Miembros</h1>
            {showAlert && <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>{responseMessage}</Alert>}
            <Form onSubmit={handleSubmit} className="form-animated">
              <Form.Group controlId="nombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="carnet">
                <Form.Label>Carnet</Form.Label>
                <Form.Control type="text" name="carnet" value={formData.carnet} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="celular">
                <Form.Label>Celular</Form.Label>
                <Form.Control type="text" name="celular" value={formData.celular} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="fecha_nacimiento">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control type="date" name="fecha_nacimiento" value={formData.fecha_nacimiento} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="ciudad">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control type="text" name="ciudad" value={formData.ciudad} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="direccion">
                <Form.Label>Dirección</Form.Label>
                <Form.Control type="text" name="direccion" value={formData.direccion} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="codigo">
                <Form.Label>Código del Cliente</Form.Label>
                <Form.Control type="text" name="codigo" value={formData.codigo} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="fechaInicial">
                <Form.Label>Fecha Inicial</Form.Label>
                <Form.Control type="date" name="fechaInicial" value={formData.fechaInicial} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="fechaFinal">
                <Form.Label>Fecha Final</Form.Label>
                <Form.Control type="date" name="fechaFinal" value={formData.fechaFinal} onChange={handleChange} required />
              </Form.Group>
              <Button variant="primary" type="submit" className="btn-animated">
                Registrar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateMember;
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Table, Container, Spinner, Alert, Form, Button } from 'react-bootstrap';
import NavBar from '../navbar/NavBar';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './Admin.css';
import FetchTotal from '../fetchTotal/FetchTotal';

const Admin = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [codigo, setCodigo] = useState('');
  const [pointsToSubtract, setPointsToSubtract] = useState(0);

  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate('/'); // Redirige a la página de inicio de sesión si no está autenticado
    }
  }, [auth, navigate]);

  const handleCodigoChange = (e) => {
    setCodigo(e.target.value);
  };

  const handlePointsChange = (e) => {
    setPointsToSubtract(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://qrscan.best.com.bo/admin.php', { codigo });
      if (response.data.success) {
        setData([response.data.data]);
      } else {
        setData([]);
        setError(response.data.message);
      }
    } catch (error) {
      setError('Error al conectar con el servidor');
    } finally {
      setLoading(false);
    }
  };

  const handleSubtractPoints = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://qrscan.best.com.bo/admin.php', { codigo, pointsToSubtract });
      if (response.data.success) {
        setData([response.data.data]);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('Error al conectar con el servidor');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <FetchTotal />
      <Container>
        <h2>Datos de Usuarios</h2>
        <Form onSubmit={handleSearch} className="mb-4">
          <Form.Group controlId="formCodigo">
            <Form.Label>Buscar por Código</Form.Label>
            <Form.Control type="text" value={codigo} onChange={handleCodigoChange} placeholder="Ingrese el código" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Buscar
          </Button>
        </Form>
        {loading ? (
          <Container className="centered-container">
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          </Container>
        ) : error ? (
          <Container className="centered-container">
            <Alert variant="danger">{error}</Alert>
          </Container>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Código</th>
                  <th>Email</th>
                  <th>Celular</th>
                  <th>Fecha de Nacimiento</th>
                  <th>Ciudad</th>
                  <th>Dirección</th>
                  <th>Fecha de Registro</th>
                  <th>Puntos Totales</th>
                  <th>Puntos Usados</th>
                  <th>Puntos Restantes</th>
                </tr>
              </thead>
              <tbody>
                {data.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.nombre}</td>
                    <td>{user.codigo}</td>
                    <td>{user.email}</td>
                    <td>{user.celular}</td>
                    <td>{user.fecha_nacimiento}</td>
                    <td>{user.ciudad}</td>
                    <td>{user.direccion}</td>
                    <td>{user.fecha_registro}</td>
                    <td>{user.puntos}</td>
                    <td>{user.pts_usados}</td>
                    <td>{user.pts_restantes}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Form onSubmit={handleSubtractPoints} className="mb-4">
              <Form.Group controlId="formPoints">
                <Form.Label>Restar Puntos</Form.Label>
                <Form.Control type="number" value={pointsToSubtract} onChange={handlePointsChange} placeholder="Ingrese los puntos a restar" />
              </Form.Group>
              <input placeholder='FACTURA'/> <br/>
              <Button variant="danger" type="submit">
                Restar Puntos
              </Button>
            </Form>
          </>
        )}
      </Container>
    </div>
  );
};

export default Admin;
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Navbar, Nav,Dropdown } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import './NavBar.css';

const NavBar = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Navbar variant="dark" expand="lg" className="navbar custom-navbar">
      <Navbar.Brand as={Link} to="/" className='custom-brand'>
        ComunidadBEST
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link as={Link} to="/">Inicio</Nav.Link>
          <Nav.Link as={Link} to="/about">Beneficios</Nav.Link>
          <Nav.Link as={Link} to="/points">Ver Puntos</Nav.Link>
          <Nav.Link as={Link} to="/clientform">Únete</Nav.Link>
          {auth ? (
            <>
            <Nav.Link as={Link} to="/admin">Administrar</Nav.Link>
            <Nav.Link as={Link} to="/createmember">Crear Miembro</Nav.Link>
            <Dropdown >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Bienvenido, {auth.username}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Cerrar Sesión</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </>
            
          ) : (
            <Nav.Link as={Link} to="/login">Iniciar Sesión</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
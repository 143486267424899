import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientForm.css';
import NavBar from '../navbar/NavBar';
import formBest1 from '../../assets/form-best-1.jpg';
import formBest2 from '../../assets/form-best-2.jpg';
import formBest3 from '../../assets/form-best-3.jpg';
import formBest4 from '../../assets/form-best-4.jpg';
import formBest5 from '../../assets/form-best-5.jpg';
import formBest6 from '../../assets/form-best-6.jpg';
import formBest7 from '../../assets/form-best-7.jpg';
import formBest8 from '../../assets/form-best-8.jpg';
import LOGO from '../../assets/logo-best.jpg';

const ClientForm = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    carnet: '',
    email: '',
    celular: '',
    fecha_nacimiento: '',
    ciudad: '',
    direccion: '',
    fechaInicial: '',
    fechaFinal: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const sliderRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const registerResponse = await axios.post('https://qrscan.best.com.bo/createClient.php', formData);
      setResponseMessage(registerResponse.data.message);
      setShowAlert(true);
    } catch (error) {
      setResponseMessage('Error al registrar el miembro: ' + error.message);
      setShowAlert(true);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true
  };

  const handleContinue = () => {
    setShowWelcome(false);
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className='clientform-page'>
      <NavBar />
      {showWelcome ? (
        <Container className="welcome-container">
          <img src={LOGO} alt="Best Logo" className="logo" />
          <h1>¡Hola! Bienvenid@ al registro al Club Best</h1>
          <p>Estás a un paso de obtener los mejores beneficios para ti</p>
          <Button variant="primary" className="btn-animated" onClick={handleContinue}>
            Continuar
          </Button>
        </Container>
      ) : (
        <Container className="dynamic-form-container">
          <h1 className="text-center">Registro de Miembros</h1>
          {showAlert && <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>{responseMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Slider ref={sliderRef} {...settings}>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest1} alt="Imagen 1" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Nombre</h2>
                        <p>Por favor, ingrese su nombre completo.</p>
                        <Form.Group controlId="nombre">
                          <Form.Control type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={nextSlide}>
                            Siguiente
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest2} alt="Imagen 2" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Carnet/NIT</h2>
                        <p>Por favor, ingrese su número de carnet.</p>
                        <Form.Group controlId="carnet">
                          <Form.Control type="text" name="carnet" value={formData.carnet} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={prevSlide}>
                            Atrás
                          </Button>
                          <Button variant="secondary" className="btn-animated" onClick={nextSlide}>
                            Siguiente
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest3} alt="Imagen 3" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Email</h2>
                        <p>Por favor, ingrese su dirección de correo electrónico.</p>
                        <Form.Group controlId="email">
                          <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={prevSlide}>
                            Atrás
                          </Button>
                          <Button variant="secondary" className="btn-animated" onClick={nextSlide}>
                            Siguiente
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest4} alt="Imagen 4" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Celular</h2>
                        <p>Por favor, ingrese su número de celular.</p>
                        <Form.Group controlId="celular">
                          <Form.Control type="text" name="celular" value={formData.celular} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={prevSlide}>
                            Atrás
                          </Button>
                          <Button variant="secondary" className="btn-animated" onClick={nextSlide}>
                            Siguiente
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest5} alt="Imagen 5" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Fecha de Nacimiento</h2>
                        <p>Por favor, ingrese su fecha de nacimiento.</p>
                        <Form.Group controlId="fecha_nacimiento">
                          <Form.Control type="date" name="fecha_nacimiento" value={formData.fecha_nacimiento} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={prevSlide}>
                            Atrás
                          </Button>
                          <Button variant="secondary" className="btn-animated" onClick={nextSlide}>
                            Siguiente
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest6} alt="Imagen 6" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Ciudad</h2>
                        <p>Por favor, ingrese su ciudad de residencia.</p>
                        <Form.Group controlId="ciudad">
                          <Form.Control type="text" name="ciudad" value={formData.ciudad} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={prevSlide}>
                            Atrás
                          </Button>
                          <Button variant="secondary" className="btn-animated" onClick={nextSlide}>
                            Siguiente
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="slide">
                <div className="card">
                  <Row>
                    <Col xs={12} sm={12} md={5}>
                      <img src={formBest7} alt="Imagen 7" className="card-img img-fluid" />
                    </Col>
                    <Col xs={12} sm={12} md={7}>
                      <div className="card-content">
                        <h2>Dirección</h2>
                        <p>Por favor, ingrese su dirección completa.</p>
                        <Form.Group controlId="direccion">
                          <Form.Control type="text" name="direccion" value={formData.direccion} onChange={handleChange} required />
                        </Form.Group>
                        <div className="card-buttons">
                          <Button variant="secondary" className="btn-animated" onClick={prevSlide}>
                            Atrás
                          </Button>
                          <Button variant="primary" type="submit" className="btn-animated">
                            Registrar
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Slider>
          </Form>
        </Container>
      )}
    </div>
  );
};

export default ClientForm;
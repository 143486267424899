import './QRScan.css';
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CryptoJS from 'crypto-js';
import Logo from '../../assets/logo-best.jpg';

const QRScan = () => {
  const [data, setData] = useState('Por favor escaneé el código QR...');

  function decryptQRCode (encryptedCode) {
    const key = CryptoJS.SHA256(process.env.REACT_APP_CRYPTO_KEY).toString(CryptoJS.enc.Hex).substr(0,32);

    const iv = CryptoJS.SHA256(process.env.REACT_APP_CRYPTO_IV).toString(CryptoJS.enc.Hex).substr(0,16);

    /* Codigo Encriptar */
    /*
    const output = CryptoJS.AES.encrypt("Message to encrypt", CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
    }).toString();

    console.log(output);

    var output2ndB64 = CryptoJS.enc.Utf8.parse(output).toString(CryptoJS.enc.Base64);
    console.log(output2ndB64);
    */

    var decrypted = CryptoJS.AES.decrypt(encryptedCode, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
    }).toString(CryptoJS.enc.Utf8);

    console.log(decrypted);

    if (decrypted.startsWith('{"nombre":')) {
      const data = JSON.parse(decrypted);
      const dateObj = new Date(data.fecha_nacimiento);
      dateObj.setDate(dateObj.getDate() + 1);
      const formattedDate = dateObj.toLocaleDateString('es-BO');

      const fechaRegistroObj = new Date(data.fecha_registro);
      const today = new Date();

      const dayDifference = Math.floor((today - fechaRegistroObj) / (1000 * 60 * 60 * 24));
      const monthDifference = Math.floor(dayDifference / 30);

      const antiguedadEnMeses = monthDifference > 1 ? `${monthDifference} meses` : ' > 1 mes';

      return "Usuario Registrado en el Comunidad Best\n\n" +
        "Nombre Completo: " + data.nombre + "\n" +
        "Carnet Identidad: " + data.carnet + "\n" +
        "Correo Electrónico: " + data.email + "\n" +
        "Celular: " + data.celular + "\n" +
        "Fecha de Nacimiento: " + formattedDate + "\n" +
        "Ciudad: " + data.ciudad + "\n" +
        "Direccion: " + data.direccion + "\n" +
        "Fecha Registro: " + data.fecha_registro + "\n" +
        "Antigüedad en Meses: " + antiguedadEnMeses;
    } else {
      return "Código QR no válido";
    }
  }

  return (
    <Container className="mt-5 mb-5 scanner-app" align="center" fluid>
      <Row>
        <Col xl="4" lg="6" md="6" sm="12" className="mx-auto text-center">
          <Image src={Logo} width={200} fluid />
          <h1 className="mt-3">Escáner QR Comunidad Best</h1>
          <QrReader
            delay={ 300 }
            onResult={(result, error) => {
              if (!!result) {
                setData(decryptQRCode(result?.text));
              }
              if (!!error) {
                console.info(error);
              }
            }}
            constraints={ {facingMode: 'environment'} }
          />
          <p style={{ whiteSpace: 'pre-line' }}>{data}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default QRScan;

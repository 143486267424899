import React, { useState, useEffect } from 'react';
import './SignUp.css';
import NavBar from '../navbar/NavBar';

const Signup = () => {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
  const [iframeWidth, setIframeWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(window.innerHeight);
      setIframeWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="signup-page">
      <div className="iframe-container">
        <iframe
          src="https://best.com.bo/formulario-best/"
          title="Formulario de Registro"
          frameBorder="0"
          allowFullScreen
          style={{ width: iframeWidth, height: iframeHeight }}
        ></iframe>
      </div>
    </div>
  );
};

export default Signup;
import React from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import { Container, Row, Col } from 'react-bootstrap';
import './About.css';
import NavBar from '../navbar/NavBar';
const About = () => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFBD33', '#8D33FF', '#FF3333'];
  const { color } = useSpring({
    from: { color: colors[0] },
    to: async (next) => {
      while (true) {
        for (const color of colors) {
          await next({ color });
        }
      }
    },
    config: { duration: 2000 },
  });
  

  return (
    <div className="about-page">
      <NavBar />
      <Container>
        <Row>
          <Col>
            <animated.div style={props} className="about-content">
            <animated.h1 style={{ color,textAlign:'center' }}>Beneficios de pertenecer a la</animated.h1>
            <animated.h1 style={{ color,textAlign:'center' }}>COMUNIDAD BEST</animated.h1>
            <p>
            Nos complace ofrecerte una serie de beneficios diseñados para hacer que tu experiencia de compra sea aún más gratificante. Al unirte a nuestra comunidad, podrás disfrutar de descuentos exclusivos, recompensas especiales y ventajas únicas que harán cada compra aún más especial. A continuación, te presentamos los detalles de los beneficios que obtendrás al ser parte de Comunidad Best:
            </p>
            <ul>
              <li>Beneficio de <animated.span style={{ color}}>10% de descuento</animated.span>  por ingreso a la comunidad en la primera compra</li>
              <li>Beneficio en tu <animated.span style={{ color}}>cumpleaños</animated.span></li>
                  <ul>
                    <li>Tu puntaje en las compras que realices durante el mes cumpleañero se duplica</li>
                    <li>Recibe un regalo Gratuito</li>
                  </ul>
              <li>Por cada compra que realices, acumularás puntos equivalentes al <animated.span style={{ color}}>1%</animated.span>  del valor de la compra</li>
              <li>Productos con puntaje especial para canje</li>
              <li>Primicia en <animated.span style={{ color}}>LIQUIDACIONES/CAMBIOS de TEMPORADA/DESCUENTOS</animated.span></li>
              <li>Primicia en lanzamiento con <animated.span style={{ color}}>precio especial</animated.span></li>
            </ul>
            <p>
            Únete a Comunidad Best y comienza a disfrutar de estas increíbles ventajas hoy mismo. ¡Tu lealtad merece ser recompensada!
            </p>
              <Link to="/" className="bttn primary">
                Volver a la Página Principal
              </Link>
            </animated.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
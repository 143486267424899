import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, Spinner, Alert, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
const FetchTotal = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [updated, setUpdated] = useState(false);

  const fechaInicial = '01/01/2023';
  const fechaFinal = '11/09/2024';
 /*  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate('/'); // Redirige a la página de inicio de sesión si no está autenticado
    }
  }, [auth, navigate]);

 */


  const handleFetchData = async () => {
    setLoading(true);
    setError('');
    setUpdated(false);
    try {
      // Obtener todos los códigos de la base de datos
      const response = await axios.get('https://qrscan.best.com.bo/obtener_codigos.php');
      const codigosBaseDatos = ['CE-0000317','CE-0049629']

      // Obtener los datos correspondientes a los códigos
      const responses = await Promise.all(
        codigosBaseDatos.map(codigo =>
          axios.get('https://qrscan.best.com.bo/obtener_total.php', {
            params: {
              codigo,
              fechaInicial,
              fechaFinal
            }
          })
        )
      );
      setData(responses.map(response => response.data));
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setError('Error al obtener los datos');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePoints = async () => {
    if (data.length > 0) {
      setLoading(true);
      setError('');
      try {
        await Promise.all(
          data.map(user =>
            axios.post('https://qrscan.best.com.bo/actpoints.php', {
              codigo: user.codigo,
              puntos: Math.round(user.porcentaje)
            })
          )
        );
        alert('Puntos actualizados exitosamente');
        setUpdated(true);
      } catch (error) {
        console.error('Error al actualizar los puntos:', error);
        setError('Error al actualizar los puntos');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Por favor, obtenga los datos primero.');
    }
  };

  return (
    <div>
      <Container>
        <h2>Obtener Total y Actualizar Puntos</h2>
        <Button onClick={handleFetchData} className="mb-4">Obtener Total</Button>
        {loading ? (
          <Container className="centered-container">
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          </Container>
        ) : error ? (
          <Container className="centered-container">
            <Alert variant="danger">{error}</Alert>
          </Container>
        ) : (
          data.length > 0 && (
            <div>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Código del Cliente</th>
                    <th>Nombre del Cliente</th>
                    <th>Monto Total</th>
                    <th>1% del Monto Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((user, index) => (
                    <tr key={index} style={{ backgroundColor: updated ? 'green' : 'inherit' }}>
                      <td>{user.codigo}</td>
                      <td>{user.nombre}</td>
                      <td>{user.total}</td>
                      <td>{Math.round(user.porcentaje)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )
        )}
        <Button onClick={handleUpdatePoints} className="mb-4" variant="danger">
          Actualizar Puntos
        </Button>
      </Container>
    </div>
  );
};

export default FetchTotal;
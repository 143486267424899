import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Fade, Slide } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo-best.jpg';
import NavBar from '../navbar/NavBar';
import './LandingPage.css';
import '../Bttn/Bttn.css';
import ParticlesBackground from '../particles/ParticlesBackground';

const LandingPage = () => {
  const ref = useRef([]);
  const [items, set] = useState([]);
  const transitions = useTransition(items, {
    from: {
      height: 0,
      innerHeight: 0,
      transform: 'perspective(600px) rotateX(0deg)',
      color: '#8fa5b6',
    },
    enter: [
      { opacity: 1, height: 80, innerHeight: 80 },
      { transform: 'perspective(600px) rotateX(180deg)', color: '#28d79f' },
      { transform: 'perspective(600px) rotateX(0deg)' },
    ],
    leave: [{ color: '#c23369' }, { innerHeight: 0 }, { opacity: 0, height: 0 }],
    update: { color: '#28b4d7' },
  });

  const reset = useCallback(() => {
    ref.current.forEach(clearTimeout);
    ref.current = [];
    ref.current.push(setTimeout(() => set(['Bienvenido a ', 'COMUNIDAD BEST']), 2000));
    ref.current.push(setTimeout(() => set(['COMUNIDAD BEST']), 5000));
    ref.current.push(setTimeout(() => set(['Bienvenido a ', 'COMUNIDAD BEST']), 8000));
  }, []);

  useEffect(() => {
    reset();
    return () => ref.current.forEach(clearTimeout);
  }, [reset]);

  return (
    <div className="landing-page">
      <ParticlesBackground />
      <NavBar />
      <Container className="landing-content">
        <Row className="align-items-center">
          <Col md={6} className="text-center">
            <Slide>
              <img src={Logo} alt="BEST Logo" className="logo" />
            </Slide>
          </Col>
          <Col md={6}>
            <Fade>
              <div className="main">
                {transitions(({ innerHeight, ...rest }, item) => (
                  <animated.div className="transitionsItem" style={rest} onClick={reset}>
                    <animated.div>{item}</animated.div>
                  </animated.div>
                ))}
              </div>
              <p>
                La comunidad BEST es una organización dedicada a promover el
                desarrollo personal y profesional de sus miembros a través de
                diversas actividades y eventos. Únete a nosotros para explorar
                nuevas oportunidades y crecer juntos.
              </p>
              <Link to="/qrscan" className="bttn primary">
                Ir a QR SCAN
              </Link>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPage;
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/landingPage/LandingPage';
import QRScan from './components/qrScan/QRScan';
import Login from './components/login/Login';
import Signup from './components/signup/SignUp';
import About from './components/about/About';
import WatchPoints from './components/WatchPoints/WatchPoints';
import { AuthProvider } from './components/contexts/AuthContext';
import CreateMember from './components/createmembers/CreateMember';
import Admin from './components/admin/Admin';
import FetchTotal from './components/fetchTotal/FetchTotal';
import ClientForm from './components/ClientForm/ClientForm';
import './App.css';
function App() {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/qrscan" element={<QRScan />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/about" element={<About />} />
        <Route path="/points" element={<WatchPoints />} />
        <Route path="/createmember" element={<CreateMember />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/fetchtotal" element={<FetchTotal />} />
        <Route path="/clientform" element={<ClientForm />} />
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;